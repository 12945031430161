'use client'; // Error components must be Client Components

import { Button, Flex, Stack, Text, Title } from '@mantine/core';
import Link from 'next/link';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLottie } from '~/Components/Shared/Lottie';
const color = {
  light: '#f8f9fa',
  dark: '#1F1F1F',
  auto: '#f8f9fa',
} as const;
export default function ErrorPage({ error, reset }: { error: Error; reset: () => void }) {
  const { t } = useTranslation('errorPage');
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <Stack>
      <Flex justify={'center'} pos="relative" mah={'400px'} mt="xl">
        <LazyLottie
          getJson={() => import('../../assets/lottie/error.json')}
          loop
          id="error"
          onThemeChange={(errorIllustration, theme) => ({
            ...errorIllustration,
            layers: errorIllustration.layers.map((layer) => {
              if (layer.sc) {
                return {
                  ...layer,
                  sc: color[theme],
                };
              }
              return layer;
            }),
          })}
          width={442}
          height={490}
        />
      </Flex>
      <Stack align="center" justify="center" mb="md" gap={0}>
        <Title order={1}>{t('Oops!')}</Title>
        <Text size={'xl'} fw="500">
          {t('Something went wrong')}
        </Text>
      </Stack>

      <Stack align="center" justify="center">
        <Button onClick={reset}>{t('Try again')}</Button>
        <Text>{t('or')}</Text>
        <Link href="/cards">
          <Button variant="light">{t('Go Back To Cards')}</Button>
        </Link>
      </Stack>
    </Stack>
  );
}
