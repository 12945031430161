import { type MantineColorScheme, Skeleton, useMantineColorScheme } from '@mantine/core';
import { type LottieComponentProps } from 'lottie-react';
import { Suspense, lazy, useEffect, useState } from 'react';

const LazyLottieComponent = lazy(() => import('lottie-react'));

interface LottieProps<T extends Record<string, unknown>> {
  getJson: () => Promise<T>;
  id: string;
  onThemeChange?: (json: T, theme: MantineColorScheme) => T;
}

export function LazyLottie<T extends Record<string, unknown>>({
  getJson,
  onThemeChange,
  id,
  ref,
  ...props
}: LottieProps<T> & Omit<LottieComponentProps, 'animationData'>) {
  const [data, setData] = useState<T | null>(null);
  const [manipulatedJson, setManipulatedJson] = useState(data);
  const { colorScheme } = useMantineColorScheme();

  useEffect(() => {
    async function loadLottie() {
      void import('lottie-react'); // Trigger the library lazy load even if the json is not ready
      const json = await getJson();
      setData(json);
    }
    void loadLottie();
  }, [id]);

  useEffect(() => {
    if (data && onThemeChange) {
      setManipulatedJson(onThemeChange(data, colorScheme));
    } else {
      setManipulatedJson(data);
    }
  }, [data, onThemeChange, colorScheme]);

  if (!data) return <Skeleton height={props.height} width={props.width} />;
  return (
    <Suspense fallback={<Skeleton height={props.height} width={props.width} />}>
      <LazyLottieComponent animationData={manipulatedJson} {...props} />
    </Suspense>
  );
}
